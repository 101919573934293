<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Merchants</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between"  v-if="hasPermission('AUTH_LIST_MERCHANT') || hasPermission('AUTH_READ_MERCHANT_SECRET')">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-text-field label="Merchant Code" v-model="query.code" single-line outlined @change="search($event, 'code')"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="Merchant Name" v-model="query.name" single-line outlined @change="search($event, 'name')"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md5 lg5 xl5 v-if="hasPermission('AUTH_LIST_MERCHANT') || hasPermission('AUTH_READ_MERCHANT_SECRET')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm6 md5 lg5 xl5 v-if="hasPermission('AUTH_CREATE_MERCHANT')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showMerchantForm('CREATED')">Create Merchant</v-btn>
                                <merchant-form ref="merchantForm" :selected-item="selectedMerchant" :is-show="showFormDialog" :form-type="merchantFormType" :payment-methods="paymentMethods" v-on:close-dialog="closeDialog" v-on:save-merchant="saveMerchant" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayMerchants" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMerchants" :footer-props="{'items-per-page-options': [5]}" class="elevation-2 mt-4 px-4">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.code }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.name }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.isEnabled ? 'ACTIVE' : 'INACTIVE' }} </span></td>
                        <td v-if="!paymentMethods || paymentMethods.length <= 0"> <span class="d-flex justify-center"> {{ item.merchantPaymentMethods }} </span></td>
                        <td v-for="method in paymentMethods" :key="method.code" v-else>
                            <span class="d-flex justify-center">
                                <v-icon medium>{{isPaymentMethodEnabled(item, method.code)}}</v-icon>
                            </span>
                        </td>

                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewMerchant(item)">
                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                </v-btn>
                                <v-btn fab dark x-small color="green" @click.stop="showMerchantForm('UPDATE', item)" v-if="hasPermission('AUTH_UPDATE_MERCHANT') || hasPermission('AUTH_UPDATE_MERCHANT_SECRET')">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import MerchantForm from '@/views/components/MerchantForm.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Merchants',
    components: {
        Loading,
        MerchantForm,
        DetailsForm
    },
    data() {
        return {
            //filter/search variables
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayMerchants: [],
            remainderMerchants: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            merchantFormType: 'CREATE',
            selectedMerchant: {},
            paymentMethods: [],
            page: 1,
            totalMerchants: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 5
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                code: '',
                name: ''
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'roles',
            'authorities'
        ]),
    },
    async mounted() {
        this.setHeaders()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const merchantListResponse = await API.getMerchants(pageNumber, limit, this.query)
                if (!merchantListResponse || merchantListResponse.error) {
                    //error getting data
                    console.log(`${merchantListResponse.error}`)
                } else {
                    var filteredList = merchantListResponse.merchants
                    if (page > 1 && this.remainderMerchants.length > 0) {
                        filteredList = this.remainderMerchants.concat(filteredList)
                    }
                    this.totalMerchants = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMerchants = filteredList.slice(0, end)
                    this.remainderMerchants = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMerchants / itemsPerPage
                    const hasMoreResponse = await API.getMerchants(this.totalMerchants, 1, this.query)
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        //error getting data
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.merchants
                        if(hasMoreList.length > 0) {
                          this.totalMerchants = this.totalMerchants + 1
                          this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
                this.getPaymentMethods()
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        async getPaymentMethods() {
            const paymentMethodListResponse = await API.getPaymentMethods('0', '100', '', '')
            this.setHeaders()
            if (!paymentMethodListResponse || paymentMethodListResponse.error) {
                //error getting data
                console.log(`${paymentMethodListResponse.error}`)
                this.headers.splice(this.headers.length - 1, 0, {
                    text: 'Merchant Payment Method',
                    value: 'merchantPaymentMethods'
                })
            } else {
                this.paymentMethods = paymentMethodListResponse.paymentMethods ? paymentMethodListResponse.paymentMethods.filter(x => x.isEnabled === true) : []
                var headers = this.headers
                this.paymentMethods.forEach(item => {
                    headers.splice(headers.length - 1, 0, {
                        text: item.name,
                        value: item.code
                    })
                });
                headers = [...new Map(headers.map(item => [item.text, item])).values()];
                this.headers = headers
            }
            this.loading = false
        },
        isPaymentMethodEnabled(item, method) {
          const paymentMethod = item.merchantPaymentMethods.find(el => el.method === method);
            if (paymentMethod) {
                return 'mdi-check'
                //return true
            }
            return 'mdi-close'
            //return false
        },
        setHeaders() {
            var headers = [{
                    text: 'Merchant Code',
                    value: 'code'
                },
                {
                    text: 'Merchant Name',
                    value: 'name'
                },
                {
                    text: 'Status',
                    value: 'isEnabled'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                }
            ]
            this.headers = headers
        },
        showMerchantForm(what, item = {}) {
            this.merchantFormType = what
            this.selectedMerchant = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveMerchant(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new merchant <strong><i>${value.merchantCode}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createMerchant(value)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                        } else {
                            this.loadingMessage = `Successfully created merchant <strong><i>${value.merchantCode}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.merchantForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating merchant <strong><i>${value.merchantCode}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateMerchant(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated merchant <strong><i>${value.merchantCode}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.merchantForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewMerchant(merchant) {
            this.details.title = merchant.name
            this.details.item = merchant
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
